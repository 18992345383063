<template>
  <v-date-picker
    v-model="date"
    mode="date"
    class="datepicker"
    :masks="{input: 'YYYY-MM-DD 00:00:00'}"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <CFormInput
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </v-date-picker>
</template>

<script>
export default {
  name: "DatePicker",
  emits:["input"],
  data() {
    return {
      date: {}
    }
  },
  methods: {
    selectDate(date) {
      this.$emit('input',
        this.getFormatedDate(date)
      )
    },
    getFormatedDate(date) {
      return date.getFullYear()+'-'+
        this.fillDateValue(date.getMonth()+1)+'-'+
        this.fillDateValue(date.getDate())+' 00:00:00'
    },
    fillDateValue(value) {
      if (value < 10) {
        return "0" + value
      } else {
        return value
      }
    },
  },
  watch: {
    date: function(newValue) {
      this.selectDate(newValue)
    }
  }
}
</script>

<style scoped>
  .datepicker {
    --dp-border-color: #b1b7c1 !important;
  }
</style>

<template>
  <BaseModal size="lg" alignment="center" :is-visible="visible" @close="visible = false" backdrop="static">
    <CModalHeader>
      <CModalTitle v-if="isNew">Новая запись</CModalTitle>
      <CModalTitle v-else>Редактирование</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CForm>
        <CRow class="mb-3" v-for="(key) in getFields()">
          <CFormLabel :for="key" class="col-sm-2 col-form-label">{{ fields[key].label }}</CFormLabel>
          <CCol sm="10">
            <ContractSelect v-if="key === 'PartnerContractID'" :value="form[key]"
                            @input="item => { form[key] = item }"/>
            <CurrencyAutoselect v-else-if="key === 'CurrencyID'" :value="form[key]"
                                @input="item => { form[key] = item }"/>
            <PlatformSelect v-else-if="key === 'PlatformID'" :value="form[key]" @input="item => { form[key] = item }"/>
            <ServicePriceSelect v-else-if="key === 'ServicePrice'" :value="form[key]"
                                @input="item => { form[key] = item }"/>
            <ServiceTypeSelect v-else-if="key === 'ServiceTypeID'" :value="form[key]"
                               @input="item => { form[key] = item }"/>
            <CenterIDSelect v-else-if="key === 'CenterID'" :value="form[key]" @input="item => { form[key] = item }"/>
            <TaskKindSelect v-else-if="key === 'TaskKind'" @input="item => { form[key] = item }"/>
            <OptionsSelect v-else-if="key === 'Sender'" :items="fields[key].options" :value="form[key]"
                           @input="item => { form[key] = item }"/>
            <OptionsSelect v-else-if="key === 'SystemID'" :items="fields[key].options" :value="form[key]"
                           @input="item => { form[key] = item }"/>
            <CFormInput v-else-if="key === 'MSISDN'" :value="form[key]"
                        @input="form[key] = $event.target.value"/>
            <DatePicker v-else-if="key === 'ActiveFrom'" :model-value="form[key]"
                        @input="item => { form[key] = item }"/>
            <FilePicker v-else-if="key === 'File'"
                        @input="form[key] = $event.target.files[0]"
                        :id="key"
            />
            <CFormTextarea v-else-if="key === 'Message'" :id="key"
                           :value="form[key]"
                           @input="form[key] = $event.target.value"
            />
            <CFormInput v-else-if="fields[key].type === 'string' && key !== 'Desc'" :id="key"
                        :value="form[key]"
                        @input="form[key] = $event.target.value"
            />
            <CFormInput v-else-if="fields[key].type === 'int'" :id="key"
                        :value="form[key]"
                        @input="form[key] = $event.target.value"
            />
            <BoolSelect v-else-if="fields[key].type === 'bool'" :id="key" :value="form[key]"
                        @input="item => { form[key] = item }"/>
            <CFormTextarea v-else-if="fields[key].type === 'string' && key === 'Desc'" :id="key"
                           :value="form[key]"
                           @input="form[key] = $event.target.value"
            />
            <InputList v-else-if="fields[key].type === '[string]'" :items="form[key]"
                       @updateItems="items => { form[key] = items }"/>
            <CFormInput v-else :id="key"
                        class="modal-input-warning"
                        :value="form[key]"
                        @input="form[key] = $event.target.value"
            />
          </CCol>
        </CRow>
        <!--        <InputList :items="[1,2,3]"/>-->
      </CForm>


    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { this.visible = false }">
        Отменить
      </CButton>
      <CLoadingButton
        :disabled-on-loading="true"
        :loading="loading"
        color="primary"
        @click="confirm">Сохранить
      </CLoadingButton>
    </CModalFooter>
  </BaseModal>
</template>

<script>
import InputList from "../../widgets/InputList";
import {sendRequest} from "../../../services/api";
import CurrencyAutoselect from "../../widgets/form/CurrencyAutoselect";
import PlatformSelect from "../../widgets/form/PlatformSelect";
import ServicePriceSelect from "../../widgets/form/ServicePriceSelect";
import ServiceTypeSelect from "../../widgets/form/ServiceTypeSelect";
import FilePicker from "../../widgets/form/FilePicker";
import DatePicker from "../../widgets/form/DatePicker";
import PhoneInput from "../../widgets/form/PhoneInput";
import CenterIDSelect from "../../widgets/form/CenterIDSelect";
import TaskKindSelect from "../../widgets/form/TaskKindSelect";
import BaseModal from "../../widgets/BaseModal";
import BoolSelect from "../../widgets/form/BoolSelect";
import OptionsSelect from "../../widgets/form/OptionsSelect";
import ContractSelect from "../../widgets/form/ContractSelect";

export default {
  name: "FormModal",
  components: {
    ContractSelect,
    OptionsSelect,
    BoolSelect,
    BaseModal,
    TaskKindSelect,
    CenterIDSelect,
    PhoneInput,
    DatePicker,
    FilePicker, ServiceTypeSelect, ServicePriceSelect, PlatformSelect, CurrencyAutoselect, InputList
  },
  emits: ['confirm', 'close'],
  props: {
    isVisible: {type: Boolean, default: false},
    fields: {type: Object, default: {}},
    operationFields: {type: Array, default: []},
    hiddenData: {},
    data: {},
    actionName: "",
    controller: "",
    method: "",
  },
  data() {
    return {
      visible: this.isWindowVisible,
      form: {},
      isNew: true,
      loading: false
    }
  },
  methods: {
    getFields() {
      return this.operationFields.filter(field => {
        if (this.fields[field].hidden) {
          this.form[field] = this.fields[field].value
          return !this.fields[field].hidden
        }
        return true
      })
    },
    confirm() {
      this.loading = true
      let uploadData = {...this.form, ...this.hiddenData}
      console.log("Modal", this.form, this.hiddenData)

      console.log(this.controller, this.method, uploadData)
      sendRequest(this.controller, this.method, uploadData)
        .then(r => {
          this.loading = false
          if (r.data[0].meta.success) {
            this.visible = false
            this.$emit('confirm', this.actionName)
            this.$toast.success(r.data[0].result.message)
          } else {
            this.$toast.error(r.data[0].result.message)
          }
        })
    }
  },
  watch: {
    visible: function (val) {
      if (!val) {
        this.$emit('close')

      }
    },
    isVisible: function (val) {
      this.visible = val
      if (val) {
        const keys = this.operationFields
        if (this.data && Object.keys(this.data).length > 0) {

          this.form = {...this.data}
          this.isNew = false

        } else {
          this.form = {}
          keys.forEach(key => {
            if (key in this.fields) {
              this.form[key] = this.fields[key].value
            } else {
              if (this.fields[key].type === "[string]") {
                this.form[key] = []
              } else {
                this.form[key] = ""
              }
            }
          })

          this.isNew = true
        }
      }
    }
  }
}
</script>

<style scoped>
.modal-input-warning {
  background-color: var(--cui-warning);
}
</style>

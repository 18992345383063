<template>
  <CInputGroup>
    <CFormInput
      placeholder="Введите новое значение"
      :class="listItems.length>0 ? 'modal-list-input' : ''"
      @input="newItem = $event.target.value"
      v-model="newItem"
      @blur="addItem"
    />
    <CButton
      :class="listItems.length>0 ? 'modal-list-button' : ''"
      color="secondary"
      variant="outline"
      @click="addItem">
      <CIcon name="cil-plus"/>
    </CButton>
  </CInputGroup>
  <CListGroup class="modal-input-list">
    <CListGroupItem v-for="item in listItems" class="d-flex justify-content-between align-items-center">
      <div>{{ item }}</div>
      <CButton color="secondary" variant="outline" @click="deleteItem(item)"><CIcon name="cil-trash"/></CButton>
    </CListGroupItem>
  </CListGroup>
</template>

<script>
import {ref} from "vue"
export default {
  name: "InputList",
  emits:["updateItems"],
  props: {
    items: {
      type: Array,
      default: []
    },
  },
  setup(props) {
    let listItems = ref([])
    listItems.value = props.items
    return {
      listItems
    }
  },
  data() {
    return {
      newItem: "",
    }
  },
  methods: {
    addItem() {
      let item = this.newItem
      if (this.listItems.indexOf(item) === -1 && item) {
        console.log(this.listItems)
        this.listItems.push(item)
        this.newItem = ""
        this.$emit("updateItems", this.listItems)
      }
    },
    deleteItem(item) {
      this.listItems = this.listItems.filter(i => { return i !== item })
    }
  },
}
</script>

<style scoped>


.modal-list-input, .modal-list-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.modal-input-list {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.modal-input-list li {
  border-color: var(--cui-secondary);
  padding:0;
}
.modal-input-list li div {
  padding: 0 1rem;
}
.modal-input-list li button {
  border: 0;
  border-left: 1px solid var(--cui-btn-border-color, transparent);
  border-radius: 0;
}
</style>

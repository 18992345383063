<template>
  <BaseSelect
    :options="getItems()"
    placeholder="Выберите..."
  />
</template>

<script>
import BaseSelect from "../BaseSelect";
export default {
  name: "TaskKindSelect",
  components: {BaseSelect},
  methods: {
    getItems() {
      return [
        {
          value: 0,
          text: "Текст"
        },
        {
          value: 1,
          text: "Имитация"
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <BaseSelect
    :options="getItems()"
    placeholder="Выберите..."
  />
</template>

<script>
import BaseSelect from "../BaseSelect";
export default {
  name: "OptionsSelect",
  components: {BaseSelect},
  props: {
    value: {type: String, default:""},
    items: {type: Array, default:[]}
  },
  methods: {
    getItems() {
      let result = []
      let keys = Object.keys(this.items)
      keys.forEach(key => {
        result.push({
          value: key,
          text: this.items[key],
          selected: this.value == key
        })
      })
      return result
    }
  }
}
</script>

<style scoped>

</style>

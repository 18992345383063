<template>
  <BaseSelect
    :options="getItems()"
    placeholder="Выберите..."
  />
</template>

<script>
import BaseSelect from "../BaseSelect";
export default {
  name: "ServiceTypeSelect",
  components: {BaseSelect},
  props: {
    value: {type: String, default:""}
  },
  methods: {
    getItems() {
      return this.$store.getters.getAllServiceType.map(item=> {
        return {
          value: item.ID,
          text: item.TypeName,
          selected: this.value == item.ID
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

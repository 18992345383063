<template>
  <CFormInput type="phone" />
</template>

<script>
export default {
  name: "PhoneInput",
}
</script>

<style scoped>

</style>

<template>
  <BaseSelect
    placeholder="Выберите..."
    :options="getItems()"
  />
</template>

<script>
import BaseSelect from "../BaseSelect";
export default {
  name: "CenterIDSelect",
  components: {BaseSelect},
  props: {
    value: {type: String, default:""}
  },
  methods: {
    getItems() {
      return this.$store.getters.getAllCenter.map(item=> {
        return {
          value: item.ID,
          text: item.Name,
          selected: this.value == item.ID
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <CButtonGroup role="group">
    <CButton color="primary" :variant="getCurrentValue ? null : 'outline'" @click="setValue(true)">Да</CButton>
    <CButton color="primary" :variant="getCurrentValue ? 'outline' : null" @click="setValue(false)">Нет</CButton>
  </CButtonGroup>
</template>

<script>
export default {
  name: "BoolSelect",
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value
    }
  },
  mounted() {
    this.$emit('input', this.currentValue)
  },
  methods: {
    setValue(value) {
      this.currentValue=value
      this.$emit('input', value)
    }
  },
  computed: {
    getCurrentValue() {
      return this.currentValue
    }
  }
}
</script>

<style scoped>

</style>

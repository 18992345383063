<template>
  <CFormInput type="file" />
</template>

<script>
export default {
  name: "FilePicker"
}
</script>

<style scoped>

</style>
